import styled from '@emotion/styled'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Alert, Box, Button as MuiButton, CircularProgress } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch, useTypedSelector } from '../..'
import { PHONE_KULJETTAJIEN_TUKIPALVELU } from '../../constants'
import { resetStatus } from '../../reducers/createDefectSlice'
import { getColor, theme } from '../../Theme'
import { ActionStatus } from '../../types/Input'
import Button from '../button/Button'
import DefectsList from '../defects/DefectsList'
import ErrorText from '../ErrorText'
import LoadingIndicator from '../LoadingIndicator'
import Text from '../Text'
import ConfirmationDialog from './ConfirmationDialog'
import { fieldPicker } from './FormFieldCreator'
import { CreateDefectFormData, Equipment, FieldConfig, FieldData, inputTypes } from './types'

const MediumErrorText = styled(ErrorText)`
  ${theme.text('normal', 'decorative', 'bold')};
  margin: ${theme.spacing.sizes.large};
  color: red;
`

interface ActionButtonProps {
  width?: string
  marginBottom?: string
  marginTop?: string
  light?: boolean
}

const ActionButton = styled(Button)<ActionButtonProps>`
  width: ${(p) => p.width ?? '50%'};
  height: 62px;
  margin-bottom: ${(p) => p.marginBottom ?? '0'};
  margin-top: ${(p) => p.marginTop ?? '0'};
  background: ${(p) => p.light && `rgba(36, 121, 179, 0.5)`};
`

const SubHeaderText = styled(Text)`
  text-transform: uppercase;
  color: ${(p) => getColor(p.theme, ['grayDark'], ['grayPink'])};
`

const DualSelectionRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
`

const FullRowSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const InstallationItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

const ButtonText = styled.span`
  flex-grow: 1;
`

const OpenDefectsContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 5px;
`

interface CreateDefectComponentProps {
  onSubmit: (payload: CreateDefectFormData) => void
  createDefectStatus: ActionStatus
  fields: FieldConfig
  formMethods: UseFormReturn<CreateDefectFormData>
  failedToFetchOptions: Array<CallableFunction>
  selectedEquipment?: Equipment
  resetForm: (resetEquipment: boolean) => void
  scrollToTop: () => void
  openDefectsEnabled: boolean
  positionsCount: number
}

const CreateDefectComponent = ({
  onSubmit,
  createDefectStatus,
  fields,
  formMethods,
  failedToFetchOptions,
  selectedEquipment,
  resetForm,
  scrollToTop,
  openDefectsEnabled,
  positionsCount,
}: CreateDefectComponentProps): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()

  const response = useTypedSelector((state) => state.defect.response)
  // const request = useTypedSelector((state) => state.defect.request)

  const nightMode = useTypedSelector((state) => state.system.nightMode)
  const defects = useTypedSelector((state) => state.defects.openDefects)
  const fetchDefectsStatus = useTypedSelector((state) => state.defects.openStatus)

  const [id, setId] = useState(response?.id)
  const [showOpenDefects, setShowOpenDefects] = useState<boolean>(false)
  const [displayAllFields, setDisplayAllFields] = useState<boolean>(false)

  useEffect(() => {
    return () => {
      dispatch(resetStatus())
    }
  }, [dispatch])

  useEffect(() => {
    if (response?.id) {
      setId(response.id)
    }
  }, [response])

  const { handleSubmit, getValues } = formMethods

  const onSubmitHandler: SubmitHandler<CreateDefectFormData> = (data: CreateDefectFormData) => {
    onSubmit({ ...data, criticality: '3' })
  }

  const resetCreateDefectStatus = useCallback((): void => {
    dispatch(resetStatus())
  }, [dispatch])

  useEffect(() => {
    if (createDefectStatus === 'succeeded') {
      resetForm(true)
    }
  }, [createDefectStatus, resetForm])

  return (
    <FormProvider {...formMethods}>
      {failedToFetchOptions.length > 0 && (
        <MediumErrorText>{t('errors.defect.optionsFailed')}</MediumErrorText>
      )}
      <DualSelectionRowContainer>
        {fieldPicker({
          ...fields.installationGroup,
          title: t('defect.field.equipment'),
          key: 'installationGroup',
          type: inputTypes.auto,
          onChange: (value?: string) => {
            if (fields.installationGroup?.onChange) {
              fields.installationGroup.onChange(value)
            }
            setShowOpenDefects(false)
          },
        })}
        {fieldPicker({
          ...fields.defectGroup,
          title: t('defect.field.defectGroup'),
          key: 'defectGroup',
          type: inputTypes.auto,
        })}
      </DualSelectionRowContainer>

      {openDefectsEnabled && (
        <OpenDefectsContainer>
          {!showOpenDefects ? (
            <ActionButton light width="100%" onClick={() => setShowOpenDefects(defects.length > 0)}>
              <ButtonText>{t('defect.create.showOpen', { openCount: defects.length })}</ButtonText>
              {fetchDefectsStatus === 'loading' && (
                <Box paddingRight="5px">
                  <CircularProgress size={30} color="info" />
                </Box>
              )}
            </ActionButton>
          ) : (
            <>
              {selectedEquipment && (
                <SubHeaderText>
                  {t('defect.list.header.open', {
                    installationGroup: selectedEquipment.installationGroup,
                    itemId: selectedEquipment.itemId,
                  })}
                </SubHeaderText>
              )}
              <DefectsList defects={defects} t={t} nightMode={nightMode} status="open" />
              <Box textAlign="center">
                <MuiButton
                  onClick={() => {
                    setShowOpenDefects(false)
                    scrollToTop()
                  }}
                  variant="outlined"
                  sx={{ color: 'info.main' }}
                  startIcon={<CloseOutlinedIcon />}
                >
                  {t('defect.closeOpenedDefects')}
                </MuiButton>
              </Box>
            </>
          )}
        </OpenDefectsContainer>
      )}

      <InstallationItemContainer>
        {displayAllFields &&
          fieldPicker({
            ...fields.installationItem,
            title: t('defect.field.installationItem'),
            key: 'installationItem',
            type: inputTypes.item,
          } as FieldData)}
      </InstallationItemContainer>
      <FullRowSelectionContainer>
        {fieldPicker({
          ...fields.productGroup,
          title: t('defect.field.defectCode'),
          key: 'productGroup',
          type: inputTypes.auto,
        })}
        {fieldPicker({
          ...fields.defectType,
          title: t('defect.field.defectType'),
          key: 'defectType',
          type: inputTypes.auto,
        })}
        {
          // Only show the position dropdown if there are positions available
          (displayAllFields || fields.vehiclePosition.required || !!getValues('vehiclePosition')) &&
            positionsCount > 0 &&
            fieldPicker({
              ...fields.vehiclePosition,
              title: t('defect.field.position'),
              type: inputTypes.auto,
              key: 'vehiclePosition',
            })
        }
        {displayAllFields &&
          fieldPicker({
            ...fields.affectToUsage,
            title: t('defect.field.affectToUsage'),
            key: 'affectToUsage',
            type: inputTypes.auto,
          })}
        {fieldPicker({
          ...fields.comment,
          title: t('defect.field.comment'),
          key: 'comment',
          type: inputTypes.input,
        })}
        {fieldPicker({
          title: t('defect.field.attachments'),
          key: 'digitalAssetUuids',
          type: inputTypes.upload,
          instructions: t('defect.attachmentInstructions'),
          onChange: fields.digitalAssetUuids.onChange,
          fileWord: t('defect.attachmentFiles'),
          required: fields.digitalAssetUuids.required,
          files: fields.digitalAssetUuids.files,
          status: fields.digitalAssetUuids.status,
        })}
      </FullRowSelectionContainer>
      {createDefectStatus === 'loading' && <LoadingIndicator size="normal" padded={true} />}
      {createDefectStatus === 'failed' && (
        <Alert variant="filled" severity="error">
          {t('errors.defect.failed')}
        </Alert>
      )}
      {createDefectStatus === 'succeeded' && (
        <ConfirmationDialog
          closeDialog={() => {
            resetCreateDefectStatus()
            window.history.back()
          }}
          submitHandler={() => {
            resetCreateDefectStatus()
            scrollToTop()
          }}
          dialogText={t('defect.create.succeeded', { id })}
          primaryActionText={t('defect.createNew')}
          secondaryActionText={t('back')}
        />
      )}
      <SubHeaderText style={{ marginBottom: '10px' }}>
        {t('defect.create.reportRestrictiveDefectsByPhone')}{' '}
        <a
          href={`tel:${PHONE_KULJETTAJIEN_TUKIPALVELU}`}
          style={{ color: getColor({ nightMode }, ['primaryBlue'], ['primaryBlue']) }}
        >
          {PHONE_KULJETTAJIEN_TUKIPALVELU}
        </a>
      </SubHeaderText>
      <ActionButton
        width="100%"
        marginBottom="10px"
        light
        onClick={() => {
          setDisplayAllFields(!displayAllFields)
        }}
      >
        {displayAllFields
          ? t('defect.create.hideOptionalFields')
          : t('defect.create.displayAllFields')}
      </ActionButton>
      <ActionButton
        width="100%"
        marginBottom="50px"
        marginTop="10px"
        onClick={handleSubmit(onSubmitHandler)}
      >
        {t('send')}
      </ActionButton>
    </FormProvider>
  )
}

export default CreateDefectComponent
