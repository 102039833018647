import type { Timestamp } from './types'

type Change = {
  type: string
  title: string
  description: string
}

type Version = {
  number: string
  timestamp: Timestamp
  changes: Array<Change>
}

type TypedChange = (title: string, description: string) => Change

const version = (number: string, timestamp: string, changes: Array<Change>): Version => ({
  number,
  timestamp,
  changes,
})

const changeType =
  (type: string) =>
  (title: string, description: string): Change => ({
    type,
    title,
    description,
  })

const newFeature: TypedChange = changeType('new-feature')
const featureChange: TypedChange = changeType('change')
const bugfix: TypedChange = changeType('bugfix')

const changelog_dev: Array<Version> = [
  version('5.14.0-beta-1', '2025-01-31T14:00:00', [
    newFeature('Vikailmoittaminen', 'Siivousviat'),
    newFeature('Vikailmoittaminen', 'Sijaintien valinta littera- ja vikakoodikohtaisesti'),
    newFeature('Vikailmoittaminen', 'Vikailmoitukset lähijunille ja tavaravaunuille'),
  ]),
  version('5.7.0-beta-6', '2024-09-12T14:00:00', [
    newFeature(
      'Kaukoliikenteen työvuorot',
      'Kaukoliikenteen työvuorot näyttävät vahvat vapaat ja heittopäivät. Myös ICS kalenterissa'
    ),
  ]),
  version('5.6.12', '2024-09-11T11:00:00', [
    bugfix(
      'Lähiliikenteen työvuorot',
      'Lähiliikenteen työvuoroilla ollut puuttuvia lähtöraiteita, korjattua taustajärjestelmän käsittelyä.'
    ),
    bugfix(
      'Ajotietotuloste',
      'Ajotietotulosteen latauksessa näkynyt virheellinen varoitusteksti Kuplan käytöstä. Teksti poistettu.'
    ),
    newFeature(
      'Yleinen',
      'Tekninen vienti, jolla ei ole vaikutusta tuotantoympäristössä. SM-liikenteen merkittävä häiriötilanne ja lähiliikenteen työvuorojen pseudonymisointi.'
    ),
  ]),
  version('5.7.0-beta-5', '2024-08-29T16:00:00', [
    featureChange('Lahiksen työvuorot', 'Lahiksen työvuorot näkyvät pseudonymisoituna devilla'),
  ]),
  version('5.6.3', '2024-06-19T10:00:00', [
    bugfix(
      'Liito-häiriöviestit',
      'Korjaus Liito-häiriöviestien lataamiseen; viestejä ladataan vasta kun käyttäjän henkilöryhmä on tiedossa.'
    ),
  ]),
  version('5.6.0', '2024-06-05T12:00:00', [
    newFeature(
      'Liito-häiriöviestit',
      'Liito-häiriöviestien näkyminen konduktöörien-pilottiryhmälle.'
    ),
  ]),
  version('5.5.5', '2024-03-25T09:00:00', [
    featureChange('Taustajärjestelmä', 'Optimointeja taustajärjeselmän tekemiin kyselyihin'),
    bugfix('Vikailmoittaminen', 'Korjaus tiedostojen lataamiseen'),
    bugfix('Veksi / Lähiveksi -ilmoitukset', 'Korjaus ilmoitusten näkymiseen'),
  ]),
  version('5.5.2', '2024-03-06T11:00:00', [
    bugfix('Huoltotyöt', 'Korjaus huoltokuljettajien roolin tunnistukseen'),
  ]),
  version('5.5.1', '2024-03-06T11:00:00', [
    bugfix('Henkilökuntahaku', 'Korjaus tietojen näyttöön'),
  ]),
  version('5.3.1', '2024-03-05T09:00:00', [
    featureChange(
      'Vikailmoittaminen',
      'Yksinkertaistettu vikailmoittamisen lomaketta, lisätty mahdollisuus ladata myös suljettuja vikoja'
    ),
    bugfix('Vikailmoittaminen', 'Korjaus vikojen statuksen käsittelyyn'),
    bugfix('Vikatiedot', 'Kriittisyysluokan 3 näkyminen oikein jos vialla on käyttörajoite'),
  ]),
  version('5.3.0-beta-2', '2023-02-19T16:00:00', [
    newFeature('Huoltotyöt', 'KEN-1030: Huoltokujettajille mahdollisuus tehdä pikkuhuoltoja'),
    featureChange(
      'Vikailmoittaminen',
      'KEN-1068: Parannuksia toiminnallisuuteen käyttäjäpalautteen pohjalta'
    ),
    bugfix('Vikailmoittaminen', 'KEN-1071: hyväksytty status käsitellään suljettuna statuksena'),
  ]),
  version('5.0.8-beta-1', '2023-11-02T12:00:00', [
    featureChange('Työvuorohaku', 'KEN-630: Lähiksen työvuorojen haku'),
  ]),
  version('4.14.0-beta-2', '2023-06-21T12:00:00', [
    featureChange('Työvuoropalaute', 'KEN-991: Palaute työvuorosta pois lähiksen kuljettajilta'),
    bugfix('Työvuoropalaute', 'KEN-992: eSälli palautteen antaminen, spinneri jää pyörimään'),
    bugfix(
      'Käyttörajoitteet',
      'KEN-1010: Käyttörajoitehaku ei toimi oikein jos kalustolla yli 30 rajoitetta'
    ),
    bugfix('Kalenteri', 'KEN-1017: Google kalenteriviennissä ongelmia'),
    featureChange('Yleinen', 'KEN-1018: Google Analytics poistaminen'),
  ]),
  version('4.13.0-beta-1', '2023-05-02T10:00:00', [
    newFeature(
      'Käyttöoikeudet',
      'KEN-1008: Logistiikan RO-kuljettajien ja vaihtotyönjohtajien eriyttäminen omalle roolilleen'
    ),
    bugfix('Käyttöoikeudet', 'KEN-994: Kentän frontin sessiovanhenemisen parantaminen'),
    bugfix('Energiatehokkuus', 'KEN-1011: Energiatehokkuuden korjaukset'),
  ]),
  version('4.12.0-beta-1', '2023-04-17T12:00:00', [
    newFeature('Ajoympäristö', 'KEN-1002: Luotu uusi Kenttä ajoympäristö'),
  ]),
]

export default changelog_dev
