import { FieldPath, FieldValues } from 'react-hook-form'

import { ActionStatus } from '../../types/Input'

export type DigitalAsset = {
  filename: string
  link?: string
}

export type CreateDefectPayload = {
  affectToUsage: string
  comment: string
  criticality: number
  defectGroup: string
  digitalAssetUuids: string[]
  installationGroup: string
  installationItem: string
  installationSerialNumber: string
  issuedTime: string
  reportedDefectCode: string
  restrictionCode: string
  vehiclePosition: string
}

export type CreateDefectFormData = {
  issuedTime: Date
  comment: string
  digitalAssetUuids: string[]
  defectGroup: string
  installationGroup: string
  installationItem: string
  installationSerialNumber: string
  vehiclePosition: string
  productGroup: string
  defectType: string
  criticality: string
  restrictionCode: string
  affectToUsage: string
  defectCode: string
  vehicleType: string
  vehicleSerialNumber: string
  type: 'item' | 'equipment'
}

export type DefectGroup = {
  id: string
  description: string
  useForCreateDefect: boolean
  createDefectRequirePicture: boolean
  createDefectRequirePositionInVehicle: boolean
  type: string
  sortIndex: number
}

export interface Position {
  position: string
  description: string
}

export type OptionStates = {
  [Key in keyof OptionTypes]: dataWithStatus<OptionTypes[Key]>
}

export type CommonDataState = OptionStates

export interface Item {
  itemId: string
  serialNumber: string
  parentItemId: string
  parentSerialNumber: string
  description: string
  uniqueId: string
  parentUniqueId: string
  seperator: '|'
}

export interface Restriction {
  id: string
  description: string
}

export type ItemOptions = Record<string, ItemData>

export type ItemFetchOptions = { filter: string; itemId: string }

export type Equipment = {
  installationGroup: string
  description: string
  itemId: string
  fleet?: string
}

export interface AffectToUsage {
  id: string
  description: string
}

export type DefectTree = {
  productGroups: string
  defectType: string
  criticality: '1' | '2' | '3'
  defectCode: string
  mpgDescription: string
  spgDescription: string
  vrpg1Description: string
  vrpg2Description: string
}

export type OptionTypes = {
  equipments: Equipment[]
  defectGroups: DefectGroup[]
  positions: Position[]
  defectTrees: DefectTree[]
  items: ItemOptions
  restriction: Restriction[]
  affectToUsage: AffectToUsage[]
}

export interface dataWithStatus<T> {
  data: T
  status: ActionStatus
  error?: string
}

export type CreateDefectResponse = {
  id: string
  criticality: string
}

export interface OptionFetch<T extends keyof OptionTypes> {
  id: T
  data: OptionTypes[T]
}

export type OptionsWithID<T extends keyof OptionTypes> = Promise<OptionFetch<T>>

interface BaseFieldConfigProps<T> {
  disabled?: boolean
  value?: T
  onChange?: (value?: T) => void
  required?: boolean
  status?: ActionStatus
}

export type DropdownItem = {
  value: string
  label: string
}

type SelectionTypes = Pick<
  CreateDefectFormData,
  | 'defectGroup'
  | 'installationGroup'
  | 'vehiclePosition'
  | 'productGroup'
  | 'defectType'
  | 'criticality'
  | 'restrictionCode'
  | 'affectToUsage'
>

type SelectionConfig = {
  [key in keyof SelectionTypes]: BaseFieldConfigProps<SelectionTypes[key]> & {
    options: DropdownItem[]
    checkboxOnChange?: (value?: boolean) => void
    checkboxRequired?: boolean
    multiline?: boolean
  }
}

type InstallationItemConfig = {
  installationItem: BaseFieldConfigProps<CreateDefectFormData['installationItem']> & {
    options: ItemOptions
    root: ItemData
  }
}

export interface AssetIdentifier {
  id: string
  filename: string
}

export type AssetIdentifiers = {
  [id: AssetIdentifier['id']]: AssetIdentifier['filename']
}

type DigitalAssetConfig = {
  digitalAssetUuids: BaseFieldConfigProps<CreateDefectFormData['digitalAssetUuids']> & {
    files: AssetIdentifiers
  }
}

type OtherFormTypes = Pick<CreateDefectFormData, 'comment' | 'issuedTime'>

type OtherFormConfig = {
  [key in keyof OtherFormTypes]: BaseFieldConfigProps<OtherFormTypes[key]>
}

export type FieldConfig = OtherFormConfig &
  SelectionConfig &
  DigitalAssetConfig &
  InstallationItemConfig

export interface FormControls<T extends FieldValues> {
  name: FieldPath<T>
  label?: string
  required?: boolean
  isDisabled?: boolean
  status?: ActionStatus
}

export interface AutoCompleteOptions {
  options: DropdownItem[]
  customOnChange?: (value?: string) => void
  defaultValue?: string
  multiline?: boolean
}

export enum inputTypes {
  select = 'select',
  input = 'input',
  upload = 'upload',
  datetime = 'datetime',
  item = 'item',
  auto = 'auto',
  autoWithCheckbox = 'checkbox',
}

interface BaseFieldData {
  title?: string
  required?: boolean
  key: keyof CreateDefectFormData
  status?: ActionStatus
  disabled?: boolean
  order?: number
}

export interface Selection extends BaseFieldData {
  type: inputTypes.select
  options: DropdownItem[]
  onChange?: (value?: string) => void
  value?: string
}

export interface AutoComplete extends BaseFieldData {
  type: inputTypes.auto
  options: DropdownItem[]
  onChange?: (value?: string) => void
  value?: string
  multiline?: boolean
}

export interface Input extends BaseFieldData {
  type: inputTypes.input
}

export interface Upload extends BaseFieldData {
  type: inputTypes.upload
  instructions: string
  fileWord: string
  files: AssetIdentifiers
  onChange?: (value?: string[]) => void
}

export interface ItemData {
  itemId: string
  serialNumber: string
  parentItemId: string
  parentSerialNumber: string
  description: string
  uniqueId: string
  parentUniqueId: string
  seperator: '|'
}

export interface Item extends BaseFieldData {
  type: inputTypes.item
  options: ItemOptions
  onChange?: (value?: string) => void
  root: ItemData
}

export type FieldData = Selection | Input | Upload | Item | AutoComplete

export interface CardData {
  title?: string
  placeholder?: string
  data: FieldData[]
}

export interface SvgProps {
  fill?: string
  size?: number
  stroke?: string
}

export type Tree<T> = T & { children: Tree<T>[] }

export interface InputTreeOptions {
  options: ItemOptions
  customOnChange?: (value?: string) => void
  rootEquipment: ItemData
}

export type Status = 'open' | 'closed'
